<template>
  <div class="collect">
    <!-- 头部 -->
    <van-nav-bar
      title="收藏商品"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
      :border="false"
      fixed
      :z-index="101"
      class="dark_bg dark_text_white"
      right-text="删除"
    />

    <!-- 内容 -->
    <!-- <van-tabs
      v-model="active"
      title-active-color="#d84b41"
      color="#d84b41"
      title-inactive-color="#333"
      swipeable
      :border="false"
      @change="onChange"
      class="dark_vant_black"
    > -->
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$globalConfig.finishedText"
      @load="onLoad"
      :error.sync="error"
      offset:50
      :immediate-check="checkis"
    >
      <!-- <van-tab
          v-for="item in collectList"
          :key="item.id"
          :title="item.categoryName"
        > -->
      <van-checkbox-group v-model="result">
        <van-checkbox
          v-for="goods in collectList"
          :key="goods.spuId"
          :name="goods.spuId"
        >
          <section class="collection">
            <!-- :desc="goods.description" -->
            <van-card
              :price="goods.showPrice"
              :desc="goods.description"
              :thumb="goods.img"
              @click="goGoodsDetail(goods.spuId)"
            >
              <p slot="title" class="goods_name">
                <span class="title-style">{{
                  goods.title
                }}</span>
              </p>
            </van-card>
            <!-- <span
              class="iconfont icon-shanchu del_collect"
              @click.stop="
                openPopup(goods.userId, goodsIndex, goods.id, goods.spuId)
              "
            ></span> -->
            <!-- <p class="similar_goods">相似商品</p> -->
          </section>
        </van-checkbox>
      </van-checkbox-group>

      <!-- 删除提示组件 -->
      <van-dialog
        v-model="showDel"
        show-cancel-button
        class="del-tip"
        @confirm="delCollect()"
      >
        <div class="del-msg">
          <p>
            <img src="../../../../static/images/rubbish.png" alt />
          </p>
          <p>确认删除该收藏吗</p>
        </div>
      </van-dialog>

      <!-- </van-tab> -->
      <!-- <p slot="loading">
        <img
          src="../../../assets/images/index.messenger-typing-preloader.svg"
          alt
          style="width: 20%"
        />
      </p> -->
      <loading-infinity slot="loading"></loading-infinity>
    </van-list>
    <!-- </van-tabs> -->
  </div>
</template>

<script>
import { NavBar, Tab, Tabs, Card, List, Dialog } from "vant";
let accessToken = localStorage.getItem("token");
export default {
  components: {
    [NavBar.name]: NavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Card.name]: Card,
    [List.name]: List,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      loading: true,
      finished: false,
      error: false,
      currentPage: 1,
      totalPageNo: Number,
      checkis: false,
      showDel: false,
      categoryId: "", // 类别id
      goGoodsDetail: this.Base.goGoodsDetail,
      collectList: [], // 收藏列表
      delCategoryId: "",
      delIndex: "",
      delId: "",
      active: "0",
      result: [],
    };
  },
  created() {
    this.initData();
    // this.$nextTick(() => {
    //   // this.size = document.documentElement.style.fontSize;
    //   this.size = window.getComputedStyle(document.documentElement)["fontSize"];
    //   console.log(this.size);
    //   let showsize = this.size.split("p")[0];
    //   console.log(showsize);

    //   document.documentElement.style.fontSize = showsize / 3.125 + "px";
    // });
  },

  // beforeDestroy() {
  //   document.documentElement.style.fontSize = this.size;
  // },

  methods: {
    openPopup(categoryId, index, id, spuId) {
      this.showDel = true;
      this.delCategoryId = categoryId;
      this.delIndex = index;
      this.delId = spuId;
    },
    onClickLeft() {
      this.$router.back();
    },
    // 切换tab的时候
    // onChange(index, title) {
    //   this.categoryId = this.collectList[index].categoryId;
    //   // 再次触发上拉加载
    //   this.initData(index);
    //   this.currentPage = 1;
    //   this.totalPage = Number;
    //   this.finished = false;
    // },

    //删除收藏
    delCollect() {
      this.$api.my
        .newcollectAddress({
          userId: this.delCategoryId,
          spuId: this.delId, // 收藏对象Id
        })
        .then((res) => {
          if (res.errno == 200) {
            // this.collectList.forEach((item) => {
            //   // if (this.categoryId == item.categoryId) {
            //   item.splice(this.delIndex, 1);
            //   // }
            // });
            this.initData();
          }
        });
    },
    //多选删除收藏
    onClickRight() {
      console.log(this.result);
      if (this.result.length === 0) {
        this.$toast("请选择需要删除的收藏");
        return;
      }
      let storeId;
      if (localStorage.getItem("storeId")) {
        storeId = localStorage.getItem("storeId");
      } else {
        storeId = undefined;
      }

      this.$api.my
        .newcollectAddress({
          userId: localStorage.getItem("customerId"),
          spuIds: this.result.join(","), // 收藏对象Id
          storeId,
        })
        .then((res) => {
          if (res.errno == 200) {
            this.$toast("取消收藏成功!");
            // this.collectList.forEach((item) => {
            //   // if (this.categoryId == item.categoryId) {
            //   item.splice(this.delIndex, 1);
            //   // }
            // });
            this.initData();
          }
        });
    },
    initData() {
      this.$api.my
        .collectGoodsAddress({
          userId: localStorage.getItem("customerId"),
          storeId: localStorage.storeId,
        })
        .then((res) => {
          if (res.errno === 200) {
            this.collectList = res.data.items;
            this.collectList.forEach((v) => {
              v.price = (v.price / 100).toFixed(2);
            });
            // this.categoryId = this.collectList[index].categoryId;
            this.totalPageNo = res.data.totalPageNo;
            this.loading = false; // 关闭上拉加载中
            // this.cancatData();
          }
        });
    },
    // 初始化数据+分页获取数据
    cancatData() {
      this.currentPage++;
      if (this.currentPage > this.totalPageNo) {
        this.loading = false;
        // 恢复currentPage和totalPage的值，以供分类分页使用
        return (this.finished = true);
      }
      // 未登录的状态就不发起请求
      this.$api.my
        .collectGoodsAddress({
          userId: localStorage.getItem("customerId"),
          pageNo: this.currentPage,
          storeId: localStorage.storeId,
        })
        .then((res) => {
          if (res.errno == 200) {
            this.pageNo = res.data.pageNo;
            this.loading = false; // 关闭上拉加载中
            // this.collectList.forEach((item) => {
            // if (this.categoryId == item.categoryId) {
            res.data.items.forEach((v) => {
              v.price = (v.price / 100).toFixed(2);
            });
            this.collectList = this.collectList.concat(res.data.items);
            // }
            // });
          } else {
            this.error = true;
          }
        });
    },
    onLoad() {
      // 异步获取数据
      setTimeout(() => {
        this.cancatData();
      }, 2000);
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../../less/element.less";
@red: #c83f3f;
.collect {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 1.088rem 0 0.32rem;
  // background-color: #f0eded;

  .del-tip {
    width: 60%;
    .del-msg {
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      padding: 0.32rem;
      p {
        line-height: 0.64rem;
        img {
          width: 40%;
        }
      }
    }
    & /deep/ .van-dialog__footer {
      width: 100%;
      box-sizing: border-box;
      padding: 0 0.128rem 0.32rem;
      &::after {
        border: none;
      }
      .van-button {
        border: 1px solid #ce4434;
        border-radius: 0.96rem;
        letter-spacing: 1px;
        margin: 0 0.128rem;
        height: 0;
        line-height: 0;
        padding: 0.32rem 0;
        color: #ce4434;
        &.van-dialog__confirm {
          color: #fff;
          background-color: #ce4434;
        }
      }
    }
    & /deep/ .van-hairline--top {
      &::after {
        border: none;
      }
    }
  }
  .van-nav-bar {
    color: #373737;
    letter-spacing: 1px;
    .van-icon {
      color: #373737;
    }
  }

  .van-tabs {
    & /deep/ .van-tabs__wrap {
      position: fixed;
      width: 100%;
      top: 0.9rem;
      left: 0;
      z-index: 300;
    }
    .van-tabs__content {
      .collection {
        // padding: 0 0.128rem;
        // width: 100%;
        // box-sizing: border-box;
        // padding: 0 .5rem /* 25/50 */;

        position: relative;
        margin-top: 0.16rem;
        .van-card {
          // width: calc(100vw - 1rem /* 50/50 */);
          // padding: 0.2rem /* 10/50 */ 0.3rem /* 15/50 */;
          // background-color: #fff;
          // border-radius: 0.6rem;
          .van-card__price {
            color: @red;
            font-size: 0.32rem;
          }
          .van-card__content {
            box-sizing: border-box;
            padding: 0.12rem;
          }
        }
        .goods_name {
          .list(row);
          max-height: 50px;
          line-height: 0.38rem;
          font-size: 0.272rem;
          color: black;

          justify-content: space-between;
          .del_collect {
            font-size: 0.38rem;
          }
        }
        .similar_goods {
          position: absolute;
          bottom: 0.24rem;
          right: 0.32rem;
          font-size: 0.28rem;
          color: #999;
          border: 1px solid #999;
          text-align: center;
          padding: 0.11rem 0.128rem;
          border-radius: 0.32rem;
        }
      }
    }
  }

  // .collect {
  // background-color: #f0eded;
  // }
  .collection {
    margin: 10px 0;
    // border-radius: 0.32rem;
    // background-color: #fff;
    // margin: 3%;
    // background-color: #f0eded;
  }
  .iconfont {
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 0.48rem;
  }
  .van-card__content {
    margin-left: 0.4rem;
    // margin-left: .5rem /* 25/50 */;
    padding-right: 20px;
  }
  // .van-card {
  //   padding: 10px 16px;
  //   border-radius: 0.32rem;
  //   background-color: #fff;
  // }
}
/deep/.van-checkbox__label {
  margin-left: -0.18rem;
}
/deep/ .van-card__thumb img {
  margin-left: 0.4rem;
  // margin-right: 5px;
  width: 1.76rem /* 88/50 */;
  height: 1.76rem /* 88/50 */;
  max-width: 88px;
  max-height: 88px;
}
/deep/.van-checkbox__icon {
  position: relative;
  top: 0;
  z-index: 99999;
  // left: 0.36rem /* 18/50 */;
  left: 0.6rem;
}
/deep/ .van-card {
  width: calc(100vw - 1rem /* 50/50 */);
  padding: 0.2rem /* 10/50 */ 0.3rem /* 15/50 */;
  background-color: #fff;
  border-radius: 0.32rem;
  margin-left: .2rem /* 10/50 */;
}
/deep/.van-card__desc {
  width: 45vw;
}
.title-style {
  display: block;
  width: 45vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}
</style>
<style lang='less'>
@red: #c83f3f;
// .van-tabs__nav{
//     background-color: #f0f0f0;

// }
// .van-tabs__line{
//         background-color: @red;
//     }
</style>

